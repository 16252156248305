<template>
  <v-container>
    <v-row class="d-flex align-center justify-center header">
      <v-col cols="auto">
        <h2 class="header__title_bold">Profile</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-form ref="form">
          <TextField
              class="mb-lg-10 mb-5"
              :placeholder="$t('profile.firstName')"
              v-model="form.firstName"
              :label="$t('profile.firstName')"
              :persistent-placeholder="true"
              required
          />
          <TextField
              class="mb-lg-10 mb-5"
              :placeholder="$t('profile.lastName')"
              :label="$t('profile.lastName')"
              v-model="form.lastName"
              required
          />
          <TextField
              :placeholder="$t('profile.email')"
              :label="$t('profile.email')"
              v-model="form.email"
              required
          />
          <div v-if="form.emailConfirm" class="mb-lg-10 mb-5" style="font-size: 2rem">Email: Confirmed</div>
          <v-btn class="mb-lg-10 mb-5" color="primary" v-if="!form.emailConfirm">Confirmation email</v-btn>
          <PhoneField
              :disabled="true"
              class="mb-lg-10 mb-5"
              :placeholder="$t('profile.phone')"
              :label="$t('profile.phone')"
              v-model="form.phone"
              required
            />
          <TextField
              class="mb-lg-10 mb-5"
              :placeholder="$t('address.address1')"
              :label="$t('address.address1')"
              v-model="form.address"
              required
          />
          <TextField
              class="mb-lg-10 mb-5"
              :placeholder="$t('address.city')"
              :label="$t('profile.city')"
              v-model="form.city"
              required
          />
          <TextField
              class="mb-lg-10 mb-5"
              :placeholder="$t('address.state')"
              :label="$t('profile.state')"
              v-model="form.state"
              required
          />
          <TextField
              class="mb-lg-10 mb-5"
              :placeholder="$t('address.zip')"
              :label="$t('profile.zip')"
              v-model="form.zip"
              required
          />
        </v-form>
        <v-container>
          <v-btn
              type="submit"
              class="btn btn--pattern mb-15"
              block
              depressed
              @click="save"
          >
            <span class="btn__text btn__text--white"> {{ $t('accountPage.update') }}</span>
          </v-btn>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TextField from '@/components/TextField';
import Http from '@/api/Http';
import PhoneField from '@/components/PhoneField'

export default {
  name: 'AccountProfile',
  components: {
    PhoneField,
    TextField
  },
  data() {
    return {
      name: '',
      form: {},
      api: Http.use('data'),
      notify: false,
      notifyMessage: ''
    }
  },
  created() {
    this.api.get('user')
      .then(user => {
        this.form = {...user};
      })
    .catch(err => {
      console.log(err);
    });
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        this.api.patch('user', this.form)
          .then(() => {
            this.$toast(this.$t('profile.updated'));
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }
}
</script>
<style scoped>
.header {
  color: var(--v-secondary-base);
  text-align: center;
  line-height: 100%;
}
.btn {
  background: url(../assets/Start_Preserving_Background.svg);
  background-size: cover;
}
</style>
